import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {apiError, checkEmail} from '../../../../utils/ExpensiveFunction'
import ErrorHandling from './ErrorHandling'
import API from '../../../../utils/api'
import {useToken, useUser} from '../../../../app/hooks'
import styled from 'styled-components'
import {useNavigate} from 'react-router-dom'
import {Checkbox, message} from 'antd'
import '../../../../CRM/assets/font.css'
import {ReactComponent as LoginLogo} from '../../../../assets/HomeTownLogin/LoginLogo.svg'
import {ReactComponent as HomeTownLogo} from '../../../../assets/HomeTownLogin/HomeTownLogo.svg'


const LoginForm = ({setIsSignUpModalOpen}) => {
  const {t} = useTranslation()

  const [error, setError] = useState<string>('')
  const [success, setSuccess] = useState<string>('')
  const {mutate} = useToken()
  const {mutate: usernameMutate} = useUser()
  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [isCheck, setIsCheck] = useState(false)

  const loginUrl = window.location.href;


  useEffect(() => {
    // 저장된 이메일이 있다면 가져와서 초기값 설정
    const savedEmail = localStorage.getItem('savedEmail');

    if (savedEmail) {
      setEmail(savedEmail);
      setIsCheck(true); // 저장된 이메일이 있다면 체크박스를 자동으로 체크
    }
  }, []);

  const onChangeEmail = e => {
    setEmail(e.target.value)
  }

  const onChangePassword = e => {
    setPassword(e.target.value)
  }

  const handleSubmit = async () => {

    if (isCheck) {
      localStorage.setItem('savedEmail', email);
    } else {
      localStorage.removeItem('savedEmail');
    }


    const res = checkEmail(email)
    if (typeof res === 'string') return setError(res)

    try {
      const res = await API.send('POST', '/auth/signin', null, {
        email,
        password,
      })

      if (res.result.success) {
        mutate(res?.result?.result?.token);
        mutate(res?.result?.username);
        message.success('로그인 성공!');
        navigate('/admin/callback', {state: loginUrl});
      } else {
        const errorResult = apiError(t, res.result.msgcode)
        setError(t(errorResult))
        message.error(res.result.message)
      }
    } catch (e) {
      message.error('로그인 에러 발생');
      console.log(e, 'ERROR LOGIN')
    }
  }

  return (
    <Container>
      <LoginWrap>
        <LoginLogoSVG as={LoginLogo}/>

        <LoginInputSection>
          <LoginInputWrap>
            <InputTitle>아이디</InputTitle>
            <LoginInput onChange={onChangeEmail} value={email}></LoginInput>
          </LoginInputWrap>
          <LoginInputWrap>
            <InputTitle>비밀번호</InputTitle>
            <LoginInput onChange={onChangePassword} type={'password'}></LoginInput>
          </LoginInputWrap>
        </LoginInputSection>

        <LoginButton
          onClick={event => {
            event.preventDefault()
            handleSubmit()
          }}>
          로그인
        </LoginButton>

        <SignUpWrapper>
          <SignUpButton onClick={() => setIsSignUpModalOpen(true)}>회원가입</SignUpButton>
          <FindUserInfo>아이디·비밀번호 찾기</FindUserInfo>
        </SignUpWrapper>

        <CheckWrap>
          <Checkbox checked={isCheck} onChange={event => setIsCheck(event.target.checked)} />
          <CheckLabel onClick={() => setIsCheck(!isCheck)}>아이디 저장</CheckLabel>
        </CheckWrap>
      </LoginWrap>

      <CommercialSide>
        <TextCard>
          <SmallText>광고주는</SmallText>
          최저 비용으로 최대 광고효과를
        </TextCard>

        <TextCard>
          <SmallText>부동산중개사무소는</SmallText>
          편리한 매물광고와 추가수익까지
        </TextCard>

        <LogoWrapper>
          <HomeTownLogoSVG as={HomeTownLogo}/>
          <LogoText>디지털알리미</LogoText>
        </LogoWrapper>

      </CommercialSide>
    </Container>
  )
}

export default React.memo(LoginForm)

const Container = styled.div`
    width: 858px;
    height: 520px;
    display: flex;
`

const LoginWrap = styled.div`
    width: 429px;
    height: 520px;
    background-color: white;
    padding-top: 82px;
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const LoginLogoSVG = styled.svg`
    width: 85px;
    margin-bottom: 40px;
`


const InputTitle = styled.div`
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    color: #000000CC;
    font-family: Pretendard, sans-serif;
    margin-bottom: 8px;
`

const LoginInputWrap = styled.div`

`

const LoginInputSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

const LoginInput = styled.input`
    border: 1px solid #00000033;
    width: 349px;
    height: 45px;
    padding-left: 10px;
`

const LoginButton = styled.button`
    width: 349px;
    height: 56px;
    background-color: #530DAD;
    box-shadow: 0px 4px 8px 0px #0000001A;
    border-radius: 2px;
    margin-top: 40px;

    color: #FFFFFF;
    font-family: Pretendard, sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 21.48px;
`

const SignUpWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 12px;
`

const CheckWrap = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: flex-start;
    width: 100%;
    margin-top: 20px;
`

const CheckLabel = styled.div`
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
`

const SignUpButton = styled.div`
    color: #000000;
    font-family: Pretendard, sans-serif;
    font-size: 15px;
    line-height: 17.9px;
    font-weight: 500;
    cursor: pointer;
`

const FindUserInfo = styled.div`
    color: #00000099;
    font-family: Pretendard, sans-serif;
    font-size: 15px;
    line-height: 17.9px;
    font-weight: 500;
    cursor: pointer;
`

const CommercialSide = styled.div`
    width: 420px;
    height: 520px;
    background-color: #300C5F;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const TextCard = styled.div`
    width: 361px;
    height: 99px;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 10px 0px #530DAD1A;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;


    color: #530DAD;
    font-weight: 800;
    font-size: 20px;
    line-height: 22.1px;
    font-family: NanumSquare Neo, sans-serif;
    text-align: center;
`

const SmallText = styled.div`
    color: #000000;
    font-weight: 700;
    font-size: 16px;
    line-height: 17.68px;
    font-family: NanumSquare Neo, sans-serif;
`

const HomeTownLogoSVG = styled.svg`
    margin-top: 77px;
`

const LogoWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
`

const LogoText = styled.div`
    color: white;
    font-size: 26px;
    font-weight: 700;
`