import React, { useEffect, useState } from 'react'
import { SideBarMenu, SideBarWrapper } from './styles'
import { useNavigate } from 'react-router-dom'
import { Icon } from '../Main/styles'
import { FaChevronRight } from 'react-icons/fa6'
import { useToken } from '../../../app/hooks'
import styled from 'styled-components'
import { ReactComponent as HomeLogo } from '../../../assets/Home/HomeLogo.svg'
import { ReactComponent as SettingIcon } from '../../../assets/Home/SettingIcon.svg'
import {useUserInfo} from "../../../HomeTown/hooks/Auth/GetUserInfo";

export const SideBar = ({ current, view, setAdDetailModal, setAgentDetailModal, setAdminDetailModal}) => {
	const { data,mutate } = useToken();
	const { userInfo, mutateUserInfo } = useUserInfo();
	const navigate = useNavigate();

	const [menu, setMenu] = useState<any>([]);

	const menuClick = menu => {
		navigate('/admin' + menu)
	}

	// useEffect(() => {
	// 	console.log('userInfo', userInfo)
	// }, [userInfo]);


	useEffect(() => {

		if( userInfo?.is_agent === 1) {
			setMenu([
				{name: '매물관리', url: '/saleProperty'},
				{name: '자체광고', url: '/selfCommercial'},
				{ name: '광고관리', url: '/commercial' },
				{name: '출력관리', url: '/outputManage'},
				{name: '사용료관리', url: '/transaction'},
				{name: '광고수익관리', url: '/calculation'},
				{name: '공지사항', url: '/notice'},
				{name: '1:1문의', url: '/inquireList'},
				{name: '환경설정', url: '/settingAgent'},
			]);
		} else if (userInfo?.is_ad === 1) {
			setMenu([
				{ name: '광고신청', url: '/customerCommercial' },
				{name: '광고처리스트', url: '/mediaList'},
				{name: '예치금관리', url: '/deposit'},
				{name: '공지사항', url: '/notice'},
				{name: '1:1문의', url: '/inquireList'},
			]);
		} else {
			setMenu([
				{ name: '회원관리', url: '/userManage' },
				{name: '광고요금관리', url: '/commercialFee'},
				{name: '결제관리', url: '/paymentManage'},
				{name: '광고수익관리', url: '/calculationAdmin'},
				{name: '콘텐츠관리', url: '/contentsManage'},
				{name: '공지사항', url: '/noticeAdmin'},
				{name: '1:1문의', url: '/inquireAdmin'},
				{name: '환경설정', url: '/settingAdmin'},
			]);
		}


	}, [userInfo])

	return (
		<SideBarWrapper style={{ display: view ? 'block' : 'none' }}>
			<HomeLogoSection>
				<HomeLogo width={106} />
			</HomeLogoSection>

			<UserInfoWrapper>

				{userInfo?.is_ad === 1 && <UserTypeBadge>광고주</UserTypeBadge>}
				{userInfo?.is_agent === 1 && <UserTypeBadge>매체주</UserTypeBadge>}
				{userInfo?.is_admin === 1 && <UserTypeBadge>관리자</UserTypeBadge>}

				{userInfo?.is_ad === 1 && <UserName>{userInfo?.store} <UserSettingIconSVG onClick={() => setAdDetailModal(true)} as={SettingIcon}/></UserName>}
				{userInfo?.is_agent === 1 && <UserName>{userInfo?.store} <UserSettingIconSVG onClick={() => setAgentDetailModal(true)} as={SettingIcon}/></UserName>}
				{userInfo?.is_admin === 1 && <UserName>{userInfo?.name} <UserSettingIconSVG onClick={() => setAdminDetailModal(true)} as={SettingIcon}/></UserName>}
			</UserInfoWrapper>

			{userInfo?.is_agent === 1 &&
				<>
					<SideBarMenu>
						{menu.map((item, index) => {
							return (
								<>
									<MenuWrapper>
										<Menu
											key={index}
											onClick={() => {
												menuClick(item.url)
											}}
											openMenu={current === item.url}
										>
											{item.name}
											<Icon><FaChevronRight /></Icon>
										</Menu>
									</MenuWrapper>
									{((index === 2) || (index === 4)) && 	<ItemListLine/>}
								</>
							)
						})}
					</SideBarMenu>
				</>
			}

			{userInfo?.is_ad === 1 &&
				<SideBarMenu>
					{menu.map((item, index) => {
						return (
							<>
								<MenuWrapper>
									<Menu
										key={index}
										onClick={() => {
											menuClick(item.url)
										}}
										openMenu={current === item.url}
									>
										{item.name}
										<Icon><FaChevronRight /></Icon>
									</Menu>
								</MenuWrapper>
								{((index === 1) || (index === 2)) && 	<ItemListLine/>}
							</>
						)
					})}
				</SideBarMenu>
			}

			{userInfo?.is_admin === 1 &&
				<SideBarMenu>
					{menu.map((item, index) => {
						return (
							<Menu
								key={index}
								onClick={() => {
									menuClick(item.url)
								}}
								openMenu={current === item.url}
							>
								{item.name}
								<Icon><FaChevronRight /></Icon>
							</Menu>
						)
					})}
				</SideBarMenu>
			}


			<SideBarTitle>
				<div>
					{data !== '' && (
						<LoginBtn onClick={() => {
							mutate(null)
							navigate('/admin/login')
						}}>로그아웃</LoginBtn>
					)}
				</div>
			</SideBarTitle>
		</SideBarWrapper>
	)
}

export default React.memo(SideBar)


const MenuWrapper = styled.div`
	padding: 0 12px;
`

export const Menu = styled.div<{ openMenu: boolean }>`
  width: 200px;
  height: 49px;
	border-radius: 4px;
	padding: 14px 10px 14px 16px;
	color: ${(props) => props.openMenu ? '#FFFFFF' : '#FFFFFF99'};
  font-weight: 500;
  font-size: 18px;
	line-height: 21.48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all .3s;
  background-color: ${(props) => props.openMenu ? '#FFFFFF33' : ''};
	
  ${Icon} {
    color: white;
		display: ${(props) => props.openMenu ? '' : 'none'};
  }

`



const HomeLogoSection = styled.div`
  width: 100%;
  display: flex;
	padding: 30px 0 28px 50px;

	border-bottom: 1px solid #3E346B;
`

const UserInfoWrapper = styled.div`
	padding: 33px 0px 30px 20px;
	border-bottom: 1px solid #3E346B;
`

const ItemListLine = styled.div`
	border-bottom: 1px solid #3E346B;
	width: 100%;
	margin: 14px 0;
`

const UserTypeBadge = styled.div`
	height: 21px;
	width: 46px;
	border-radius: 4px;
	background-color: #00000080;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 6px;
	
	color: white;
	font-weight: 500;
	font-size: 13px;
	line-height: 15.51px;
`


const UserName = styled.div`
	color: white;
	font-family: Pretendard, sans-serif;
	font-weight: 700;
	font-size: 16px;
	line-height: 19.09px;
	
	display: flex;
	align-items: center;
	gap: 5px;
	padding-left: 4px;
`

const UserSettingIconSVG = styled.svg`
	cursor: pointer;
`




export const SideBarTitle = styled.div`
  width: 100%;
  height: 60px;
  font-size: 20px;
  padding: 10px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #010101;
  font-weight: 700;
`
export const LoginBtn = styled.div`
  border: none;
  background-color: #df7777;
  color: #fff;
  font-family: Pretendard, sans-serif;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: .3s;
  &:hover {
    border: 1px solid #df7777;
    background-color: transparent;
    color: #df7777;
  }
`