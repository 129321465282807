import React, {useState, useEffect} from 'react'
import {Table, Checkbox, message} from 'antd'
import {DBFilterCheckboxArea, DBFilterWrapper, DBTableExcelBtn} from '../../../CRM/organism/DB/styles';
import styled from "styled-components";
import {useToken} from "../../../app/hooks";
import {CalculationDetail} from "./blocks/CalculationDetail";
import {formatPhoneNumber} from "../../hooks/util/formatPhoneNumber";
import {numberWithCommas} from "../../../utils/ciutils";
import {CommercialFeeList} from "../../RealEstate/Calculation/blocks/CommercialFeeList";
import {SettlementList} from "./blocks/SettlementList";


export const AdminCalculationTable = ({
                                        adminRefundList,
                                        mutateAdminRefundList,
                                        selectedRows,
                                        setSelectedRows,
                                        isAllChecked,
                                        setIsAllChecked,
                                        isSelectItem,
                                        total,
                                        page,
                                        setPage,
                                        pageSize,
                                        setPageSize,
                                        isLoading
                                      }) => {
  const [userId, setUserId] = useState(null);
  const [itemId, setItemId] = useState(null); // 내역 모달 관련
  const {data: token} = useToken();


  useEffect(() => {
    const allSelected = adminRefundList?.length > 0 && adminRefundList?.every(it => selectedRows?.includes(it.no));
    setIsAllChecked(allSelected);
  }, [selectedRows, adminRefundList]);


  const handleCheckboxChange = (e, id) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows?.filter(rowId => rowId !== id));
    }
  };


  const handleSelectAllChange = (e) => {
    setIsAllChecked(e.target.checked);
    const ids = adminRefundList?.map(db => db.no);
    setSelectedRows(e.target.checked ? ids : []);
  };


  const handleTableChange = (pagination) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };


  const customerColumns = [
    {
      dataIndex: 'no',
      title: isSelectItem ?
        (<Checkbox
          checked={isAllChecked}
          onChange={handleSelectAllChange}
        />)
        :
        'No'
      ,
      render: (value, data, index) => {
        return (
          <>
            {isSelectItem ?
              (<div className='checkbox' style={{justifyContent: 'center', display: 'flex'}}>
                <Checkbox checked={selectedRows?.includes(data.no)} key={data?.no}
                          onChange={(e) => handleCheckboxChange(e, data?.no)}/>
              </div>)
              :
              <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
            }
          </>
        )
      }
    },
    {
      dataIndex: 'name',
      title: '이름',
      render: (value, data) => {
        return (
          <div
            className='email'
            style={{
            justifyContent: 'center',
            display: 'flex',
          }}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'store',
      title: '상호명',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'email',
      title: '아이디',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'tel',
      title: '휴대폰번호',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value}</div>
        )
      }
    },
    {
      dataIndex: 'account',
      title: '계좌정보',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value}</div>
        )
      }
    },
    {
      dataIndex: 'settlementTargetMonth',
      title: '정산월',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex',}}>{value}</div>
        )
      }
    },
    {
      dataIndex: 'settlementAmount',
      title: '광고수익',
      render: (value, data) => {
        return (
          <div
            className='date'
            onClick={() => setItemId(data?.settelmentId)}
            style={{
              justifyContent: 'flex-end',
              display: 'flex',
              textDecoration: 'underline',
              cursor: 'pointer'
            }}
          >{numberWithCommas(value)}</div>
        )
      }
    },
    {
      dataIndex: 'settlementStatus',
      title: '상태',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex',}}>{value}</div>
        )
      }
    },
    {
      dataIndex: 'settlementDate',
      title: '지급일',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex',}}>{value}</div>
        )
      }
    }
  ];


  return (
    <DBFilterWrapper>

      <StyleTable columns={customerColumns}
                  dataSource={adminRefundList}
                  bordered={true}
                  loading={isLoading}
                  pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: total,
                  }}
                  onChange={handleTableChange}
      />

      {/*{orderId !== null && <PaymentModal orderIdd={orderId} setOrderIdd={setOrderId} mutate={mutateOrderList} orderList={orderList} />}*/}
      {userId !== null && <CalculationDetail userId={userId} setUserId={setUserId}/>}

      {itemId !== null && <SettlementList setItemId={setItemId} itemId={itemId}/>}

    </DBFilterWrapper>
  )
}

export default React.memo(AdminCalculationTable);

const ColumnHeader = styled.div`

`

const StyleTable = styled(Table)`
    .ant-table-thead > tr > th {
        background-color: #F4F2FF;
        color: #00000099;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.71px;
    }
`
