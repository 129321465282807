import React, {useState} from 'react'
import {Display} from "../../../CRM/organism/Main/styles";
import {AdminCalculationFilter} from "./AdminCalculationFilter";
import {AdminCalculationTable} from "./AdminCalculationTable";
import {useOrderList} from '../../../CRM/hooks/Tosme/OperateManage/Sales/OrderManage/GetOrderList'
import styled from "styled-components";
import {useAdminRefundList} from "../../hooks/Admin/AdminCalculation/GetAdminRefundList";
import API from "../../../utils/api";
import {message} from "antd";
import {useToken} from "../../../app/hooks";


export const AdminCalculation = () => {
  // 필터링
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [date, setDate] = useState('');
  const [searchWord, setSearchWord] = useState('');
  const [order, setOrder] = useState<'latest' | 'oldest'>('latest');
  const [status, setStatus] = useState<0 | 1 | 2>(0);
  const [searchType, setSearchType] = useState<'name' | 'email' | 'store' | 'tel'>('name');

  // 유틸리티
  const [categoryItem, setCategoryItem] = useState('광고주');
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [isSelectItem, setIsSelectItem] = useState(false);

  // 데이터 조회
  const {adminRefundList, mutateAdminRefundList, total, isLoading} = useAdminRefundList(page, pageSize, searchWord, searchType);
  const {data: token} = useToken();


  const getRefundExcel = () => {
    const shouldEdit = window.confirm('엑셀을 출력하시겠습니까?');
    if(shouldEdit) {
      API.get('/admin/refund/list/excel', token + '', {
      })
        .then((res: any) => {
          if (res.result.success) {
            message.success('출력되었습니다');
            window.open(res.result.result.link, '_blank');
          } else {
            message.error(res.result.message || '실패하였습니다');
          }
        })
        .catch((err: any) => {
          message.error('실패하였습니다');
        })
    }
  }

  return (
    <Display>
      <RegistSection>
        <RegistSaleButton onClick={() => getRefundExcel()}>엑셀출력</RegistSaleButton>
      </RegistSection>


      <AdminCalculationFilter
        setSearchWord={setSearchWord}
        setOrder={setOrder}
        setStatus={setStatus}
        mutateAdminRefundList={mutateAdminRefundList}
        order={order}
        selectedRows={selectedRows}
        setIsSelectItem={setIsSelectItem}
        isSelectItem={isSelectItem}
      />

      <AdminCalculationTable
        adminRefundList={adminRefundList}
        mutateAdminRefundList={mutateAdminRefundList}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        isAllChecked={isAllChecked}
        setIsAllChecked={setIsAllChecked}
        isSelectItem={isSelectItem}
        total={total}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        isLoading={isLoading}
      />
    </Display>
  )
}

const RegistSection = styled.div`
    display: flex;
    justify-content: flex-end;
`

const RegistSaleButton = styled.div`
    background-color: #530DAD;
    box-shadow: 0px 0px 10px 0px #0000000D;
    width: 140px;
    height: 42px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;

    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`
